import Link from "next/link";
import { useRouter } from "next/router";
import { SkipNavigationLink } from "@/components/atoms";
import { Navigation } from "@/components/molecules";
import Banner from "@/components/molecules/Banner";
import CustomButtonGroup from "@/components/molecules/CustomButtonGroup";
import React from "react";
import flatListToHierarchical from "@/utilities/flatListToHierarchical";
import { DashlaneIcon } from "@/components/icons";
import { EnhancedMenuItem } from "@/types/Menu";
import { HeaderProps } from "./DynamicHeader";
import { CustomButtonInterface } from "../molecules/CustomButton";

export default function EnhancedHeader({
  enhancedMenuItems,
  banner,
  variant = "white",
}: HeaderProps) {
  const { locale } = useRouter();

  const hierarchicalMenu = flatListToHierarchical(
    enhancedMenuItems
  ) as EnhancedMenuItem[];

  const mainMenuItems = hierarchicalMenu.filter(
    (item) =>
      (item.position === "left" || item.position === "right") &&
      item.structure !== "login" &&
      item.structure !== "try_free"
  );

  const menuCTAs = hierarchicalMenu.filter(
    (item) => item.structure === "login" || item.structure === "try_free"
  );

  function getButtonsData(data: EnhancedMenuItem[], isMobile: boolean = false) {
    const buttons: CustomButtonInterface[] = [];

    data.forEach((cta) => {
      if (cta.structure === "login") {
        buttons.push({
          variant: variant === "dark" ? "dark" : "light",
          href: cta.path,
          target: cta.target ?? "_self",
          text: cta.label,
          size: "sm",
          className: isMobile
            ? ""
            : "hidden xl:block focus:outline-1 focus:outline-blue-8",
          ga4Id: cta.ga4Id,
        });
      } else if (cta.structure === "try_free") {
        buttons.push({
          variant: variant === "dark" ? "white" : "dark",
          href: cta.path,
          target: cta.target ?? "_self",
          text: cta.label,
          size: "sm",
          className: isMobile
            ? "mt-2"
            : "mt-0 hidden xl:block focus:outline-1 focus:outline-blue-8",
          ga4Id: cta.ga4Id,
        });
      }
    });

    return buttons;
  }

  return (
    <header className="sticky top-0 z-30">
      <SkipNavigationLink />
      {banner && <Banner {...banner.attributes} />}
      <div className="header-background border border-l-0 border-r-0 border-t-0 border-grey-1">
        <div className="px-4 md:px-8">
          <div
            className="relative flex w-full items-center"
            style={{ height: "60px" }}
          >
            <Link
              href="/"
              className="mr-3 inline-flex items-center focus:outline-1 focus:outline-blue-8 md:mr-12"
              locale={locale}
            >
              <DashlaneIcon width="127" height="32" />
            </Link>

            <Navigation
              layout="Header"
              enhancedMenuItems={mainMenuItems}
              enhanced
            />

            <div className="ms-auto flex grow items-center justify-end space-x-2 xl:grow-0">
              <CustomButtonGroup
                align="right"
                buttons={getButtonsData(menuCTAs)}
                marginTop=""
              />
              <Navigation
                enhancedMenuItems={mainMenuItems}
                layout="Header"
                buttons={getButtonsData(menuCTAs, true)}
                mobile
              />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
