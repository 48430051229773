import { gql } from "@apollo/client";

import * as MENUS from "@/constants/menus";
import {
  BlogInfoFragment,
  FeaturedImageFragment,
  MenuItemFragment,
} from "@/fragments";
import { Container, ContentWrapper, Main } from "@/components/atoms";
import {
  AuthorSummary,
  Blocks,
  EntryHeader,
  PostAuthor,
  PostsContentCards,
  PostShare,
  PostTags,
  PreviewLoader,
  SEO,
  SignUpForm,
} from "@/components/molecules";
import { Footer, RecommendedPosts } from "@/components/organisms";
import type {
  Author,
  Block,
  FeaturedImage as FeaturedImageType,
  GeneralSettings,
  Locale,
  RecommendedPost,
  Translated,
  BlockAttributesJson,
  PostPreview,
} from "@/types";
import { Seo } from "@/types/Seo";
import JumpLinkSubnav from "@/components/molecules/JumpLinkSubnav";
import { formatLocaleForGraphQL } from "@/utilities/formatLocaleForGraphQL";
import NewMenuItemFragment from "@/fragments/NewMenuItem";
import DynamicHeader from "@/components/organisms/DynamicHeader";
import {
  GetEditorBlocksFragments,
  GetEditorBlocksKeys,
} from "@/fragments/fragmentFunctions";
import flatListToHierarchical from "@/utilities/flatListToHierarchical";
import PostHeader from "@/components/molecules/PostHeader";
import PostShareIcons from "@/components/molecules/PostShareRedesigned";
import SignUpFormRedesigned from "@/components/molecules/SignUpFormRedesigned";
import TableOfContents from "@/components/molecules/TableOfContents";
import PostSidebarCta from "@/components/molecules/PostSidebarCta";

export type Props = {
  // Seed Node isn't called for previews
  __SEED_NODE__?: {
    translations: Translated[];
  };
  data: {
    generalSettings: GeneralSettings;
    headerMenuItems: { nodes: never[] };
    footerMenuItems: { nodes: never[] };
    newMenuItems: { nodes: never[] };
    newLayoutEnabled: boolean;
    displayPostsSignupFormEnabled: boolean;
    post: {
      editorBlocks: BlockAttributesJson[];
      title: string;
      content: string;
      excerpt: string;
      customExcerpt?: string;
      customStickyBlockCheckbox?: boolean;
      customStickyBlockTitle?: string;
      customStickyBlockButtonText?: string;
      customStickyBlockButtonLink?: string;
      customStickyBlockContent?: string;
      featuredImage?: FeaturedImageType;
      hideFeaturedImage?: {
        value: "Yes" | "No";
      };
      date: string;
      modified: string;
      author: Author;
      link: string;
      uri: string;
      slug: string;
      tags: {
        nodes: {
          link: string;
          name: string;
        }[];
      };
      postNewLayoutEnabled: boolean;
      // For related posts under the same category
      categories: {
        nodes: {
          posts: {
            nodes: RecommendedPost[] | PostPreview[];
          };
          name: string;
          uri: string;
        }[];
      };
      contentType: {
        node: {
          name: string;
        };
      };
      seo: Seo;
    };
  };
  loading: boolean;
  locale: Locale;
};

export default function Component(props: Props) {
  // Needed for previews to load on the client
  if (props.loading) {
    return <PreviewLoader />;
  }

  const translated =
    props.__SEED_NODE__?.translations?.filter((each: Translated) => {
      return each.status === "publish";
    }) ?? [];
  const { title: siteTitle, description: siteDescription } =
    props.data.generalSettings;
  const primaryMenu = props.data.headerMenuItems.nodes;
  const footerMenu = props.data.footerMenuItems.nodes;
  const newMenuItems = props.data.newMenuItems.nodes;
  const newLayoutEnabled = props.data.newLayoutEnabled;
  const displayPostsSignupFormEnabled =
    props.data.displayPostsSignupFormEnabled;
  const {
    title,
    content,
    featuredImage,
    hideFeaturedImage,
    date,
    modified,
    author,
    link,
    tags,
    categories,
    seo,
    uri,
    postNewLayoutEnabled,
    customStickyBlockCheckbox,
    customStickyBlockTitle,
    customStickyBlockButtonText,
    customStickyBlockButtonLink,
    customStickyBlockContent,
  } = props.data.post;
  const showNewLayout = newLayoutEnabled && postNewLayoutEnabled;
  const posts = categories.nodes[0]?.posts.nodes;
  const postCategories = categories.nodes.map((category) => ({
    name: category.name,
    uri: category.uri,
  }));
  //const blocks = JSON.parse(props.data.post.blocksJSON);
  const blocks = flatListToHierarchical(props.data.post.editorBlocks, {
    idKey: "id",
    parentKey: "parentClientId",
    childrenKey: "innerBlocks",
  });
  const isFreeformBlock = blocks?.[0]?.name === "core/freeform";
  const shouldUseBlocks = Boolean(
    blocks[0]?.name?.includes("tenup/") ||
      blocks[0]?.attributes?.content ||
      blocks[0]?.attributes?.value ||
      blocks[0]?.innerBlocks?.length
  ); // case study posts begin with columns component where content lies within innerBlocks

  const jumpLinks = blocks.filter(
    (each: Block) => each.name === "tenup/jump-link-section"
  );

  const banner = blocks.filter((each: Block) => each.name === "tenup/banner");

  blocks.forEach((block: Block) => {
    if (block.name === "core/heading") {
      block.attributes = {
        ...block.attributes,
        isMainBlock: true,
      };
    }
  });

  //this change is to prevent an error extending the attributes within the innerBlocks
  let stringBlocks = JSON.stringify(blocks);

  return (
    <>
      <SEO
        fullHead={seo.fullHead}
        availableLocales={translated}
        uri={uri}
        locale={props.locale}
      />
      <DynamicHeader
        title={siteTitle}
        description={siteDescription}
        menuItems={primaryMenu}
        enhancedMenuItems={newMenuItems}
        availableLocales={translated}
        banner={banner?.[0]}
      />
      <Main>
        {jumpLinks.length ? (
          <JumpLinkSubnav
            jumpLinks={jumpLinks}
            excerpt={props.data.post.excerpt}
            title={props.data.post.title}
          />
        ) : null}
        <Container tagName="article">
          {!jumpLinks.length ? (
            showNewLayout ? (
              <PostHeader
                title={title}
                image={featuredImage}
                hideFeaturedImage={hideFeaturedImage}
                date={date}
                modified={modified}
                author={author}
                categories={postCategories}
                excerpt={props.data.post.customExcerpt}
                content={content}
              />
            ) : (
              <EntryHeader
                title={title}
                image={featuredImage}
                hideFeaturedImage={hideFeaturedImage}
                date={date}
                modified={modified}
                author={author}
              />
            )
          ) : null}

          {showNewLayout ? (
            <div className="relative flex gap-6 py-[60px]">
              <div className="content w-full md:w-[calc(100%-412px)]">
                <Blocks blocks={JSON.parse(stringBlocks)} />
              </div>
              <div className="sticky top-[61px] mb-6 hidden h-full w-[412px] md:block">
                <PostShareIcons url={link} title={title} className="mb-12" />
                <TableOfContents blocks={blocks} />
                {customStickyBlockCheckbox ? (
                  <SignUpFormRedesigned
                    heading={customStickyBlockTitle}
                    content={customStickyBlockContent}
                    buttonText={customStickyBlockButtonText}
                  />
                ) : (
                  <PostSidebarCta
                    heading={customStickyBlockTitle}
                    content={customStickyBlockContent}
                    buttonText={customStickyBlockButtonText}
                    buttonLink={customStickyBlockButtonLink}
                  />
                )}
              </div>
            </div>
          ) : !isFreeformBlock && shouldUseBlocks ? (
            <Blocks blocks={JSON.parse(stringBlocks)} />
          ) : (
            <ContentWrapper content={content} />
          )}

          {showNewLayout ? (
            <>
              {displayPostsSignupFormEnabled && (
                <SignUpForm width="full" variant="light" />
              )}
              {posts && (
                <PostsContentCards
                  posts={posts as PostPreview[]}
                  variant={"dark"}
                  heading={"Related articles"}
                  contentType={"card"}
                  includeTag={true}
                />
              )}
              <PostAuthor author={author} />
            </>
          ) : (
            <>
              <SignUpForm />
              {tags.nodes && <PostTags tags={tags.nodes} />}
              <PostShare
                url={link}
                title={title}
                className="flex w-full flex-col items-center justify-center py-5"
              />
              <AuthorSummary author={author} />
              {posts && (
                <RecommendedPosts
                  uri={uri}
                  posts={posts as RecommendedPost[]}
                />
              )}
            </>
          )}
        </Container>
      </Main>
      <Footer
        title={siteTitle}
        menuItems={footerMenu}
        availableLocales={translated}
        locale={props.locale}
      />
    </>
  );
}

Component.query = gql`
  ${BlogInfoFragment}
  ${GetEditorBlocksFragments()}
  ${FeaturedImageFragment}
  ${MenuItemFragment}
  ${NewMenuItemFragment}
  query GetPost(
    $databaseId: ID!
    $headerLocation: MenuLocationEnum
    $footerLocation: MenuLocationEnum
    $newMenuLocation: MenuLocationEnum
    $asPreview: Boolean = false
    $locale: LanguageCodeFilterEnum = EN
  ) {
    post(id: $databaseId, idType: DATABASE_ID, asPreview: $asPreview) {
      title
      content
      ${GetEditorBlocksKeys()}
      date
      modified
      link
      postNewLayoutEnabled
      excerpt
      customExcerpt
      customStickyBlockCheckbox
      customStickyBlockTitle
      customStickyBlockButtonText
      customStickyBlockButtonLink
      customStickyBlockContent
      uri
      ...FeaturedImageFragment
      hideFeaturedImage {
        value
      }
      author {
        node {
          description
          uri
          name
          avatar {
            url
            height
            width
          }
        }
      }
      tags {
        nodes {
          link
          name
        }
      }
      categories {
        nodes {
          posts(first: 6) {
            nodes {
              excerpt
              title
              uri
              categories {
                nodes {
                  name
                  uri
                }
              }
              ...FeaturedImageFragment
            }
          }
          name,
          uri,
        }
      }
      contentType {
        node {
          name
        }
      }
      seo {
        fullHead
      }
    }
    newLayoutEnabled
    displayPostsSignupFormEnabled
    generalSettings {
      ...BlogInfoFragment
    }
    headerMenuItems: menuItems(
      where: { location: $headerLocation, language: $locale }
      first: ${MENUS.NUMBER_OF_MENU_ITEMS}
    ) {
      nodes {
        ...MenuItemFragment
      }
    }
    footerMenuItems: menuItems(
      where: { location: $footerLocation, language: $locale }
      first: ${MENUS.NUMBER_OF_MENU_ITEMS}
    ) {
      nodes {
        ...MenuItemFragment
      }
    }
    newMenuItems: menuItems(
      where: { location: $newMenuLocation, language: $locale }
      first: ${MENUS.NUMBER_OF_MENU_ITEMS}
    ) {
      nodes {
        ...NewMenuItemFragment
      }
    }
  }
`;

Component.variables = (
  { databaseId }: { databaseId: string },
  { asPreview, locale }: { asPreview?: boolean; locale: Locale }
) => {
  return {
    asPreview,
    footerLocation: MENUS.newFooterLocation(locale),
    headerLocation: MENUS.newHeaderLocation(locale),
    locale: formatLocaleForGraphQL(locale),
    newMenuLocation: MENUS.newMenuLocation(locale),
    databaseId,
  };
};
